import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';

import styles from './PedagogyBanner.module.scss';

export default function PedagogyBanner() {
  const trackMoengageEvent = useMoengageEventTracker();

  return (
    <section className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.contentHeadingWrapper}>
          <h3>StudyIQ’s Pedagogy and Success Strategy</h3>
          {/* eslint-disable-next-line max-len */}
          <p>StudyIQ’s experienced team has developed a remarkable pedagogical model aimed at maximizing learning and retention, followed by adequate practice.</p>
        </div>

        <Link
          href="https://dl.studyiq.com/GgCq"
          target="_blank"
          className={styles.exploreNowBtn}
          onClick={() => trackMoengageEvent('home_pedagogy_clicked')}
        >
          <span>Explore Now</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>

      <div className={styles.illustrationWrapper}>
        <Image
          src="/images/PedagogyIllustration.png"
          alt="StudyIQ’s Pedagogy and Success Strategy"
          width={447.08}
          height={188}
        />
      </div>
    </section>
  );
}
